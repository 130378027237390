import { CompanySdsesFilter } from '@/interfaces/api/general/company';
import { Sds } from '@/interfaces/model/general/sds';
import { StatusId } from '@/lib/enum';
import store from '@/store';
import { dispatchGetCompanySdsesAsExcel } from '@/store/main/actionsMain';
import { ref } from 'vue';

export type ExportSdsesTarget = 'all' | 'selected';
export type ExportSdsesType = 'normal' | 'csv' | 'simple';
export type ExportSdsesFormat = 'sds' | 'product' | 'chemical';
export type ExportSdsesCharacterCode = 'utf-8' | 'shift-jis';

export const useSdsesExport = () => {
  const exportSdsesTarget = ref<ExportSdsesTarget>();
  const exportSdsesType = ref<ExportSdsesType>();
  const exportSdsesFormat = ref<ExportSdsesFormat>();
  const exportSdsesCharacterCode = ref<ExportSdsesCharacterCode>('utf-8');

  const exportSdses = async (
    selectedSdses: Sds[],
    sdsesFilter: CompanySdsesFilter
  ) => {
    const selectedSdsesUuid = selectedSdses.map((sds) => sds.uuid);

    return await dispatchGetCompanySdsesAsExcel(store, {
      companyId: store.state.main.company.uuid,
      data: {
        ...sdsesFilter,
        export_type: exportSdsesType.value as Required<ExportSdsesType>,
        export_format: exportSdsesFormat.value,
        character_code: exportSdsesCharacterCode.value,
        sds_uuid_list:
          exportSdsesTarget.value === 'selected'
            ? selectedSdsesUuid
            : undefined,
        status_id_list: [StatusId.SECOND_CHECK_DONE.value],
      },
    });
  };

  const resetExportSdsesForm = () => {
    exportSdsesTarget.value = undefined;
    exportSdsesType.value = undefined;
    exportSdsesFormat.value = undefined;
    exportSdsesCharacterCode.value = 'utf-8';
  };

  const validateExportSdses = (): string | null => {
    if (!exportSdsesTarget.value) {
      return 'エクスポート対象を指定してください';
    }
    if (!exportSdsesType.value) {
      return 'エクスポート種別を指定してください';
    }
    if (exportSdsesType.value === 'csv' && !exportSdsesFormat.value) {
      return 'エクスポート形式を指定してください';
    }
    if (
      (exportSdsesType.value === 'csv' || exportSdsesType.value === 'simple') &&
      !exportSdsesCharacterCode.value
    ) {
      return 'エクスポート文字コードを指定してください';
    }
    return null;
  };

  return {
    exportSdsesTarget,
    exportSdsesType,
    exportSdsesFormat,
    exportSdsesCharacterCode,
    resetExportSdsesForm,
    validateExportSdses,
    exportSdses,
  };
};
